import React, { Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation, Trans } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";
import i18n from "i18next";

import { fontsize_large } from "../../assets/css/variables";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import type { History, IntInput, Message, TextInput } from "../../utils/types";
import {
  allInputsValid,
  EMAILREGEX,
  PASSWORDLENGTH,
} from "../../utils/validation";
import session from "../../session";
import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import DateOfBirth from "../subcomponents/DateOfBirth";
import LanguageChoice from "../subcomponents/LanguageChoice";
import ProgressBars from "../subcomponents/ProgressBars";
import SubmitButton from "../subcomponents/SubmitButton";
import TextField from "../subcomponents/TextField";
import PrivacyContent from "./Privacy/PrivacyContent";
import TextLinkExternal from "../subcomponents/TextLinkExternal";
import VisuallyHidden from "../subcomponents/VisuallyHidden";
import { useConfig } from "../ConfigProvider";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  history: History;
  t: TFunction;
};

type State = {
  awaitingResponse: boolean;
  fullName: TextInput;
  identifier: TextInput;
  day: IntInput;
  month: IntInput;
  year: IntInput;
  email: TextInput;
  language: IntInput;
  password: TextInput;
  passwordConfirm: TextInput;
  message: Message;
};

const SpaceLink = ({ children }: { children?: React.ReactNode }) => (
  <TextLinkExternal href={useConfig().spaceUrl + "/#/signup"}>
    {children}
  </TextLinkExternal>
);

class Signup extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      awaitingResponse: false,
      fullName: { value: "", isValid: false, error: "" },
      // The honeypot field - always valid - API rejects signup if populated.
      identifier: { value: "", isValid: true, error: "" },
      day: { value: 0, isValid: false, error: "" },
      month: { value: 0, isValid: false, error: "" },
      year: { value: 0, isValid: false, error: "" },
      email: { value: "", isValid: false, error: "" },
      // New signups get the default stack language. Error/ Validation not in use.
      language: { value: 1, isValid: true, error: "" },
      password: { value: "", isValid: false, error: "" },
      passwordConfirm: { value: "", isValid: false, error: "" },
      message: { text: "" },
    };

    // If we don't want to use arrow functions for our handlers, bind this here.
    // (this: any) is recommended workaround for Flow error:
    // Cannot assign `this.onChange.bind(...)` to `this.onChange` because property `onChange` is not writable.
    // https://github.com/facebook/flow/issues/1517#issuecomment-194538151
    this.onChange = this.onChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // If we don't handle change - the field will not update as we type.
  onChange(event) {
    // Assign by deconstruct e.g. `const name = event.target.name` equiv `{ name } = event.target`
    const { name, value } = event.target;
    // We handle all fields here - but we could instead: onChangeName, onChangeEmail...
    // Set State is async so we need to pass the previous state
    // https://reactjs.org/docs/react-component.html#setstate
    this.setState(
      (oldState) => {
        // Uses computed property dynamic key name.
        return { ...oldState, [name]: { value } };
      },
      // Process in callback
      () => {
        this.validateField(name, value);
      },
    );
  }

  async componentDidMount() {
    const fetched = localStorage.getItem("language");

    const languageId = fetched ? JSON.parse(fetched).id : null;
    const langCode = fetched ? JSON.parse(fetched).code : null;

    // If we retrieve a local language object, set the language
    if (langCode !== null) {
      i18n.changeLanguage(langCode);
    }

    // Provide the language to state
    if (languageId !== null) {
      this.setState(function (prevState) {
        return {
          ...prevState,
          language: { value: languageId, isValid: true, error: "" },
        };
      });
    }
  }

  // Special for radio change since our base component doesn't deal in events.
  // Validation not necessary clientside.
  onLanguageChange(lang) {
    // Might be worth a check that we support the `lang.code`.
    // As is, will set bogus code and revert to fallback strings with console warnings.
    i18n.changeLanguage(lang.code);
    localStorage.setItem("language", JSON.stringify(lang));
    this.setState(function (prevState) {
      return {
        ...prevState,
        language: { value: lang.id, isValid: true, error: "" },
      };
    });
  }

  // Field values - front end validation.
  // Todo - move validation to shared component.
  // Todo - Implement error message on failed validation.
  validateField(name, value) {
    const {
      fullName,
      identifier,
      day,
      month,
      year,
      email,
      password,
      passwordConfirm,
    } = this.state;

    switch (name) {
      case "fullName":
        fullName.isValid = value.length > 2 && value.length < 33;
        break;
      case "identifier":
        // This is the honeypot - we want it to be valid always.
        // The api will reject sign-up if it has a value.
        identifier.isValid = true;
        break;
      case "email":
        email.isValid = EMAILREGEX.test(value);
        break;
      case "day":
        day.isValid = value > 0 && value <= 31;
        break;
      case "month":
        month.isValid = value > 0 && value <= 12;
        break;
      case "year":
        year.isValid = value > 1957 && value <= 2018;
        break;
      case "password":
        password.isValid = value.length >= PASSWORDLENGTH;
        break;
      case "passwordConfirm":
        passwordConfirm.isValid =
          password.value.length > 0 && password.value === passwordConfirm.value;
        break;
      default:
        break;
    }

    this.setState({
      fullName,
      // Honeypot field should be empty.
      identifier,
      day,
      email,
      month,
      password,
      passwordConfirm,
      year,
    });
  }

  async onSubmit(event) {
    event.preventDefault();
    // Prevent 2 submits on a double click.
    if (this.state.awaitingResponse) {
      return;
    }
    this.setState({ awaitingResponse: true });
    const {
      day,
      email,
      fullName,
      month,
      language,
      password,
      identifier,
      year,
    } = this.state;
    let response;
    // Convert to ISO 8601 Calendar date format (YYYY-MM-DD)
    const dob =
      year.value +
      "-" +
      // append value to "00" and cast as string, eg 3 -> "003"; slice 2 chars off the end.
      String("00" + month.value).slice(-2) +
      "-" +
      String("00" + day.value).slice(-2);
    try {
      response = await this.props.Api.post("/user", {
        dob: dob,
        name: fullName.value,
        userName: identifier.value,
        email: email.value,
        language_id: language.value,
        password: password.value,
        // We include here because we don't have a user id to post to consents - until the user is created.
        // Alternative is to chain another post on successful create of the user.
        // If we were able to submit, user will have consented but to be safe, using the state value.
        has_consented: true,
      });
    } catch (e) {
      // Try to get an error message from the api - hopefully translated!
      const msg = getAxiosErrorMessage(e);
      return this.setState({
        awaitingResponse: false,
        message: { text: msg },
      });
    }
    // Success - user object returned.
    try {
      // Todo - do we need setSession to return success/ failure?
      session.setSession(response.data);
    } catch (e) {
      return this.setState({
        awaitingResponse: false,
        message: { text: (e as any).message, type: "error" },
      });
    }
    this.setState({ awaitingResponse: false });

    // This is a bit messy here - will refactor
    this.props.history.push("/locator", {
      message: { text: this.props.t("pages:signup.message_thanks") },
    });
  }

  render() {
    // We are using an app and need to know a country to chose a server
    if (window.usingCordova && !localStorage.getItem("country")) {
      this.props.history.push("/get-started");
    }

    const { t } = this.props;
    const {
      awaitingResponse,
      day,
      email,
      fullName,
      language,
      message,
      month,
      password,
      passwordConfirm,
      identifier,
      year,
    } = this.state;
    const showPasswordWarning = password.value && !password.isValid;

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:signup.heading")}
            message={message}
            hideLogoutButton={true}
            hideAccountButton={true}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <p>
                <Trans i18nKey="pages:signup.subheading">
                  pre-link placeholder
                  <SpaceLink />
                  post-link placeholder
                </Trans>
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <form onSubmit={this.onSubmit}>
                <TextField
                  type="text"
                  id="fullName"
                  name="fullName"
                  label={t("pages:signup.label_name")}
                  value={fullName.value}
                  onChange={this.onChange}
                  valid={fullName.isValid}
                  required={true}
                />
                {/*
                 * No need to translate label. It's a honeypot.
                 * Also no need to be wholly dynamic, but copying pattern is easiest path.
                 */}
                <VisuallyHidden>
                  <TextField
                    type="text"
                    id="identifier"
                    name="identifier"
                    label="Identifier"
                    value={identifier.value}
                    onChange={this.onChange}
                    valid={identifier.isValid}
                    required={false}
                    ariaHide={true}
                    autoComplete="security-identifier"
                  />
                </VisuallyHidden>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  label={t("pages:signup.label_email")}
                  value={email.value}
                  onChange={this.onChange}
                  valid={email.isValid}
                  required={true}
                />
                <TextField
                  type="password"
                  id="password"
                  name="password"
                  label={t("pages:signup.label_password")}
                  value={password.value}
                  onChange={this.onChange}
                  valid={password.isValid}
                  required={true}
                  error={
                    showPasswordWarning
                      ? t("pages:signup.password_too_short")
                      : ""
                  }
                />
                <TextField
                  type="password"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  label={t("pages:signup.label_password_confirm")}
                  value={passwordConfirm.value}
                  onChange={this.onChange}
                  valid={passwordConfirm.isValid}
                  required={true}
                />
                <DateOfBirth
                  day={day.value}
                  month={month.value}
                  year={year.value}
                  onChange={this.onChange}
                />
                <LanguageChoice
                  legend={t("messagesAndSettings:language_choice")}
                  onChangeNotify={this.onLanguageChange}
                  selected={language.value}
                />
                <PrivacyTitle>{t("pages:signup.link_privacy")}</PrivacyTitle>
                <PrivacyContent
                  sections={new Set(["signup"])}
                  context={"signup"}
                />
                <BoldParagraph>
                  {t("pages:signup.privacy_p_desc")}
                </BoldParagraph>
                <SubmitButton
                  label={t("pages:signup.button_submit")}
                  name="signup"
                  noIcon={true}
                  disabled={
                    !allInputsValid([
                      day,
                      month,
                      year,
                      email,
                      fullName,
                      password,
                      passwordConfirm,
                    ]) || awaitingResponse
                  }
                />
              </form>
            </Col>
          </Row>
        </LayoutContent>
        <LayoutFooter>
          <ProgressBars count={3} position={1} />
        </LayoutFooter>
      </Layout>
    );
  }
}

const BoldParagraph = styled.p`
  font-weight: bold;
`;

const PrivacyTitle = styled.h2`
  margin-top: 2rem;
  text-align: center;
  font-size: ${fontsize_large};
`;

export default withTranslation()(withApi(Signup));
